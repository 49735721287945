<template>
	<div>
		<!-- 密钥设置 -->
		<div class="mb20"><b>密钥维护</b></div>
		<div>
			<el-form :label-position="labelPosition" label-width="95px">
				<el-form-item label="商户号:">
					{{123467654321}}
				</el-form-item>
				<el-form-item label="签名方式:">
					MD5
				</el-form-item>
				<el-form-item label="MD5密钥:">
					<el-input style="width: 400px;" type="textarea" :rows="4" placeholder="请输入内容" v-model="key"></el-input>
				</el-form-item>
				<el-form-item label="谷歌验证码:">
					<div class="flexX">
						<el-input style="width: 400px;"  class="mr20" prefix-icon="el-icon-key" placeholder="请输入谷歌验证吗" v-model="code" clearable></el-input>
						<span class="cdanger inlineBlock whiteSpaceNowrap pointer" @click="validateGcode">绑定谷歌验证</span>
					</div>
			
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="sure">上传</el-button>
				</el-form-item>
			</el-form>
			<el-dialog center width="30%" title="绑定谷歌验证" :visible.sync="innerVisible" append-to-body>
				<div class="qrcode" v-loading="innerloading">
					<img :src="qrcode" alt="">
				</div>
			</el-dialog>
		</div>
		
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				labelPosition:'left',
				key: '',
				code: '',
				innerVisible: false,
				innerloading: false,
				qrcode: '',
				
			}
		},
		created() {},
		methods: {
			validateGcode() {
				this.innerVisible = true;
				if (this.qrcode != '') {
					return
				}
				let data = {
					username: this.username,
				}
				// this.innerloading = true
				this.$api.validateGcode(data).then(res => {
					this.innerloading = false
					//1 成功   2未登录  其他失败
					if (res.status === 1) {
						let data = res.data;
						if (data != '') {
							this.qrcode = data
						} else {
							this.$message({
								type: 'info',
								message: '账号已完成谷歌验证，请输入验证码登录'
							})
						}
					} else {
						this.$message({
							type: 'info',
							message: res.msg
						});
					}
				}).catch(error => {
					this.innerloading = false
				})
			},

			sure() {

				if (isEmpty(this.key, '请输入新密码')) {
					return
				}

				if (isEmpty(this.code, '请输入谷歌验证码')) {
					return
				}
				if (!isAllNumber(this.code, '谷歌验证码只能是数字')) {
					this.$message({
						type: 'error',
						message: '谷歌验证码只能是数字'
					})
					return
				}
				let data = {
					key: this.key,
					code: this.code,
				}
				// this.dialogloading = true
				this.$api.setPayPassword(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.password = '';
						this.comPassword = '';
						this.code = '';
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})

			},
		},
		watch: {
			$route(to, from) {
				this.nowPath = this.$route.path
				this.getBreadCrumb()
				this.getTreeDirectoru()
			}
		}
	}
</script>


<style lang="scss">
	@import '@/style/variables.scss';

	.qrcode {
		// display: inline-block;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			background-color: #fff; //设置白色背景色
			box-sizing: border-box;
		}
	}
</style>
